<template>
  <div class="task-manipulations">
    <div class="task-manipulations__row">
    <span v-on:click="makeTaskDraft()"
          class="task-manipulations__button">Сделать задачу доступной для расчета</span>
      <span v-on:click="refreshTilesCache()" class="task-manipulations__button">Обновить кэш тайлового сервера</span>
      <span v-on:click="makeTaskSolved()"
            class="task-manipulations__button">Сделать задачу рассчитанной</span>
    </div>
    <div class="task-manipulations__row">
    <span v-on:click="handleCopyClick()"
          class="task-manipulations__button">Копировать задачу</span>
      <span v-on:click="handleCopyWithDataClick()"
            class="task-manipulations__button">Копировать задачу со всеми данными</span>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "TaskManipulations",
  props: {
    taskId: {
      type: String
    },
    environment: {
      type: String
    },
    tilesCacheLink: {
      type: String
    },
    taskLink: {
      type: String
    }
  },
  methods: {
    handleCopyClick() {
      this.$confirm(
          {
            message: `Эта задача будет скопирована по ссылке (shallow copy). Данные останутся из текущей задачи. Вы уверены?`,
            button: {
              yes: 'Копировать',
              no: 'Отмена',
            },
            callback: confirm => {
              if (confirm) {
                this.copyTask()
              }
            }
          }
      )
    },
    handleCopyWithDataClick() {
      this.$confirm(
          {
            message: `Все данные этой задачи будут скопированы. Вы уверены?`,
            button: {
              yes: 'Копировать',
              no: 'Отмена',
            },
            callback: confirm => {
              if (confirm) {
                this.copyTaskWithData()
              }
            }
          }
      )
    },
    refreshTilesCache: function () {
      fetch(this.tilesCacheLink)
          .then(function (response) {
            console.log(response.status);
          });
    },
    makeTaskDraft: function () {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($taskId: Uuid!) {
                taskMutation {
                    makeTaskDraft(taskId: $taskId) {
                        ok
                    }
                }
            }`,
            variables: {
              taskId: this.taskId
            }
          }).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.error(error)
      })
    },
    makeTaskSolved: function () {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($taskId: Uuid!) {
                taskMutation {
                    makeTaskSolved(taskId: $taskId) {
                        ok
                    }
                }
            }`,
            variables: {
              taskId: this.taskId
            }
          }).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.error(error)
      })
    },
    copyTask: function () {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($taskId: Uuid!) {
                taskMutation {
                    cloneTaskWithAllStages(taskId: $taskId) {
                        recordId
                    }
                }
            }`,
            variables: {
              taskId: this.taskId
            }
          }).then((data) => {
        let newTaskId = data.data.taskMutation.cloneTaskWithAllStages.recordId
        let route = this.$router.resolve({
          name: 'Task',
          params: {
            environment: this.environment,
            taskId: newTaskId
          }
        });
        window.open(route.href, '_blank');
      }).catch((error) => {
        console.error(error)
      })
    },
    copyTaskWithData: function () {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($input: CloneTaskWithAllDataInput!) {
                taskMutation {
                    cloneTaskWithAllData(input: $input) {
                        recordId
                    }
                }
            }`,
            variables: {
              input: {
                taskId: this.taskId
              }
            }
          }).then((data) => {
        let newTaskId = data.data.taskMutation.cloneTaskWithAllData.recordId
        let route = this.$router.resolve({
          name: 'Task',
          params: {
            environment: this.environment,
            taskId: newTaskId
          }
        });
        window.open(route.href, '_blank');
      }).catch((error) => {
        console.error(error)
      })
    }
  }

}
</script>

<style scoped>

.task-manipulations__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.task-manipulations__button {
  font-size: 10pt;
  border-radius: 15px;
  padding: 5px 12px;
  border: 2px solid #4f4f4f;
  text-decoration: none;
  margin-left: 5px;
  margin-right: 5px;
}

.task-manipulations__button:hover {
  background-color: #e8e8e8;
}
</style>