<template>
  <div class="task-info">
    <div class="task-info__links">
      <router-link id="main" target="_blank" :to="{ name: 'Main' }">
        На главную
      </router-link>
      <a id="web" target="_blank" v-bind:href="taskLink">Открыть задачу в веб-интерфейсе</a>
    </div>
    <div style="clear: both;"></div>
    <h2 class="task-info__name">{{ taskName }}</h2>
    <div class="task-info__additional">
      <span>Окружение <b>{{ environment }}</b></span>
      <span> | </span>
      <span class="task-info__task-id">Задача <b>{{ taskId }}</b></span><br>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskInfo",
  props: {
    taskName: {
      type: String
    },
    taskId: {
      type: String
    },
    environment: {
      type: String
    },
    taskLink: {
      type: String
    }
  }
}
</script>

<style scoped>
.task-info {
  margin-top: -25px;
  text-align: center;
}

#main {
  float: left;
  color: #252525;
  text-decoration: none;
}

#main:hover {
  color: #afafaf;
}

#web {
  float: right;
  color: #252525;
  text-decoration: none;
}

#web:hover {
  color: #afafaf;
}


h2 {
  margin-top: 20px;
}

.task-info__additional {
  margin-top: -20px;
}

</style>