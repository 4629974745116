import Vue from 'vue'
import VueRouter from 'vue-router'
import VueApollo from 'vue-apollo'
import VueConfirmDialog from "vue-confirm-dialog";

import App from "@/App";
import Main from "@/views/Main";
import TaskWrapper from "./views/TaskWrapper";

Vue.use(VueApollo)
Vue.use(VueRouter)
Vue.use(VueConfirmDialog)


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main
        },
        {
            path: '/:environment/:taskId',
            name: 'Task',
            component: TaskWrapper,
            props: true
        }
    ]
})


import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'

// Cache implementation
const cache = new InMemoryCache()

const stagingHttpLink = createHttpLink({
    uri: 'https://api.staging.plan.numdes.com/graphql',
})

const stagingApolloClient = new ApolloClient({
    link: stagingHttpLink,
    cache,
})


const productionHttpLink = createHttpLink({
    uri: 'https://api.plan.numdes.com/graphql',
})

const productionApolloClient = new ApolloClient({
    link: productionHttpLink,
    cache,
})


const apolloProvider = new VueApollo({
    clients: {
        stagingApolloClient,
        productionApolloClient
    },
    defaultClient: stagingApolloClient
})

Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

new Vue({
    router,
    apolloProvider,
    render: h => h(App),
}).$mount('#app')
