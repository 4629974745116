<template>
  <div class="stage">
    <div class="stage__header" v-on:click="toggleMethodsList()">
      <span class="stage__name">{{ stageName }}</span>
      <span class="stage__toggle"
            v-bind:class="{ 'not-visible': toggled }">►</span>
      <span class="stage__toggle"
            v-bind:class="{ 'not-visible': !toggled }">▼</span>
    </div>
    <div class="stage__methods" v-bind:class="{ 'not-visible': !toggled }">
      <hr>
      <ul>
        <li v-for="item in methods" :key="item.id">
          <Method
              v-bind:name="item.name"
              v-bind:stage-id="stageId"
              v-bind:task-id="taskId"
              v-bind:method-id="item.id">
          </Method>
          <hr>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Method from "@/components/Method";

export default {
  name: "Stage",
  components: {Method},
  props: {
    taskId: {
      type: String
    },
    stageId: {
      type: String
    },
    stageName: {
      type: String
    },
    methods: {
      type: Array
    }
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    toggleMethodsList() {
      this.toggled = !this.toggled
    },
  }
}
</script>

<style scoped>
.stage {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.stage__header {
  flex-direction: row;
}

.stage__name {
  font-size: 15pt;
}

.stage__methods {
  display: initial;
}

.stage__toggle {
  margin-left: 10px;
  display: initial;
}

.not-visible {
  display: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-left: 10px;
}

</style>