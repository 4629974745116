<template>
  <Task v-bind:environment="environment" v-bind:task-id="taskId"></Task>
</template>

<script>
import Task from "../components/Task";

export default {
  name: "TaskWrapper",
  components: {
   Task
  },
  created() {
    let apolloClientName = this.environment + "ApolloClient"
    this.$root.$apolloProvider.defaultClient = this.$root.$apolloProvider.clients[apolloClientName]
  },
  props: {
    environment: {
      type: String
    },
    taskId: {
      type: String
    }
  },
}
</script>

<style scoped>

</style>