<template>
  <div class="task-not-found">
    <h2 class="task-not-found__message">
      Задача<br>
      <span>{{ taskId }}</span> <br>
      не найдена на окружении<br>
      <span>{{ environment }}</span>
    </h2>
    <router-link class="task-not-found__return-button" :to="{ name: 'Main' }">
      Открыть другую задачу
    </router-link>
  </div>

</template>

<script>
export default {
  name: "TaskNotFound",
  props: {
    environment: {
      type: String
    },
    taskId: {
      type: String
    }
  }
}
</script>

<style scoped>

.task-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task-not-found__message {
  color: #4f4f4f;
}

.task-not-found__message span {
  color: #d05252;
}

.task-not-found__return-button {
  margin-top: 30px;
  border-radius: 7px;
  padding: 10px;
  color: #4f4f4f;
  border: 2px solid #4f4f4f;
  text-decoration: none;
}

</style>