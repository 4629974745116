<template>
  <div class="method-history">
    <div class="method-history__elements">
      <b>История изменений результатов метода</b>
      <ul>
        <li>
          Текущий <b>calculation_id: {{ currentCalculationId }}</b><br>
          Описание: {{ resolveDescription(this.description) }}
        </li>

        <li v-for="item in history" :key="item.oldCalculationId">
          <span><b>{{ formatDate(item.created) }}</b></span>
          <br>
          <span> Было: {{ item.oldCalculation.calculationId }} </span>
          <br>
          <span> Стало: {{ item.newCalculation.calculationId }}</span>
          <br>
          Описание: {{ resolveDescription(item.newCalculation.description) }}
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "MethodHistory",
  props: {
    history: {
      type: Array
    },
    currentCalculationId: {
      type: String
    },
    description: {
      type: String
    }
  },
  methods: {
    formatDate(date) {
      date = new Date(date.replace(/\s/, 'T'))

      var year = date.getFullYear();
      var month = (date.getMonth() + 1);
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();

      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      if (hour < 10) {
        hour = '0' + hour;
      }
      if (minute < 10) {
        minute = '0' + minute;
      }

      return (day + '.' + month + '.' + year + ' ' + hour + ':' + minute)
    },
    resolveDescription(description) {
      if (description === null || description === undefined || description === '') {
        return "нет"
      }
      return description
    }

  }
}
</script>

<style scoped>
.method-history {
  display: flex;
  flex-direction: column;
}

.method-history__elements {
  margin-top: 5px;
  flex-direction: row;
}

.method-history__elements span {
  margin-right: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-left: 10px;
}

li {
  margin-bottom: 10px;
}

</style>