<template>
  <div class="task" v-bind:class="{ 'task__not-loaded': !isDataLoaded }">
    <div class="task__not-found" v-bind:class="{ 'task__not-found_active': isDataLoaded && !isTaskExists }">
      <TaskNotFound v-bind:environment="environment" v-bind:task-id="taskId"></TaskNotFound>
    </div>
    <div class="task__exists" v-bind:class="{ 'task__exists_active': isDataLoaded && isTaskExists }">
      <TaskInfo
          v-bind:environment="environment"
          v-bind:task-id="taskId"
          v-bind:task-link="taskLink"
          v-bind:task-name="taskName"
      ></TaskInfo>
      <hr>
      <TaskManipulations
          v-bind:tiles-cache-link="tilesCacheLink"
          v-bind:task-id="taskId"
          v-bind:task-link="taskLink"
          v-bind:environment="environment"
      ></TaskManipulations>
      <hr>
      <ul>
        <li v-for="item in stages" :key="item.id">
          <Stage
              v-bind:task-id="taskId"
              v-bind:stage-name="item.name"
              v-bind:stage-id="item.id"
              v-bind:methods="item.methods">
          </Stage>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TaskNotFound from "@/components/TaskNotFound";
import TaskInfo from "@/components/TaskInfo";
import Stage from "@/components/Stage";
import gql from 'graphql-tag'
import TaskManipulations from "@/components/TaskManipulations";

export default {
  name: "Task",
  components: {
    TaskManipulations,
    TaskNotFound,
    TaskInfo,
    Stage
  },
  props: {
    environment: {
      type: String
    },
    taskId: {
      type: String
    }
  },
  data() {
    return {
      taskInfo: null,
      taskName: null,
      isTaskExists: false,
      isDataLoaded: false,
      stages: []
    };
  },
  computed: {
    taskLink: function () {
      let prefix = this.evalPrefix()
      return `http://${prefix}plan.numdes.com/#/result/${this.taskId}`
    },
    tilesCacheLink: function () {
      let prefix = this.evalPrefix()
      return `https://tiles.${prefix}plan.numdes.com/warm_up_cache?task_id=${this.taskId}`
    }
  },

  methods: {
    evalPrefix() {
      if (this.environment === "develop") {
        return "dev."
      } else if (this.environment === "staging") {
        return "staging."
      } else if (this.environment === "production") {
        return ""
      } else {
        console.error(`Unexpected type of environment.
         ['develop', 'staging', 'production'] were expected, but ${this.environment} was given.`)
        this.$router.push({name: 'Main'})
      }

    }
  },

  apollo: {
    taskInfo: {
      query: gql`query taskInfo($id: Uuid!)
        {
            task(taskId: $id) {
              scenario {
                id
                stages {
                    id
                    name
                    methods {
                        id
                        name
                    }
                  }
              }
              name
            }
        }`,
      variables() {
        return {
          id: this.taskId
        };
      },
      update(data) {
        this.isDataLoaded = true
        this.taskName = data.task.name
        this.isTaskExists = true
        this.stages = []
        for (let stage of data.task.scenario.stages) {
          this.stages.push(stage)
        }
        return data
      },
      error(error) {
        this.isDataLoaded = true
        this.isTaskExists = false
        console.log('errors', error.graphQLErrors)
      }
    }
  }
}
</script>

<style scoped>
.task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -30px;
  visibility: initial;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.task__not-loaded {
  visibility: hidden;
}

ul {
  padding: 0;
  list-style-type: none;
}

.task__not-found {
  display: none;
}

.task__not-found_active {
  display: initial;
}

.task__exists {
  display: none;
}

.task__exists_active {
  display: initial;
}

</style>
