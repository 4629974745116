<template>
  <div class="method-edit">
    <span class="method-edit__text">Текущий <b>calculation_id</b>  :  <b>{{ currentCalculationId }}</b></span><br>
    <div class="method-edit__input">
      <input
          class="method-edit__input-field" type="text" size="38"
          v-model="newCalculationId"
          v-on:input="getCalculationId()"
          v-bind:class="{ 'method-edit__input-field_incorrect': isCalculationIdIncorrect }"
          placeholder="Введите новое значение">
      <span
          class="method-edit__submit-button"
          v-on:click="handleUpdateClick()"
          v-bind:class="{ 'method-edit__submit-button_active': isSubmitButtonActive }">
        Обновить
      </span>
      <span
          class="method-edit__not-existing-calculation"
          v-bind:class="{ 'method-edit__not-existing-calculation_active': !isCalculationExists }">
        <b>← такого calculation_id не существует!</b>
      </span>

    </div>
    <div class="method__input-hint" v-bind:class="{ 'method__input-hint_active': isCalculationIdIncorrect }">
      <span>Значение быть в формате <b>00000000-0000-0000-0000-000000000000</b></span><br>
      <span>и должно отличаться от текущего</span>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MethodEdit",
  data: function () {
    return {
      newCalculationId: "",
      isCalculationIdIncorrect: false,
      isSubmitButtonActive: false,
      isCalculationExists: true
    };
  },
  props: {
    taskCalculationId: {
      type: String
    },
    currentCalculationId: {
      type: String
    }
  },
  methods: {
    getCalculationId() {
      this.newCalculationId = this.newCalculationId.trim()
      if (this.newCalculationId === "") {
        this.isCalculationIdIncorrect = false
        this.isSubmitButtonActive = false
        this.isCalculationExists = true
        return
      }
      let re = new RegExp(
          "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$"
      )
      let isValid = re.test(this.newCalculationId)

      isValid = isValid && this.newCalculationId !== this.currentCalculationId

      if (!isValid) {
        this.isCalculationIdIncorrect = true
        this.isSubmitButtonActive = false
        this.isCalculationExists = true
      } else {
        this.isCalculationIdIncorrect = false
        this.isSubmitButtonActive = true
        this.isCalculationExists = true
      }
    },
    handleUpdateClick() {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($input: UpdateCalculationIdInput!) {
                taskCalculationMutation {
                    updateCalculation(input: $input) {
                        ok
                    }
                }
            }`,
            variables: {
              input: {
                taskCalculationId: this.taskCalculationId,
                calculationId: this.newCalculationId,
              }
            }
          }).then(() => {
        this.$parent.handleReload()
        this.newCalculationId = ""
      }).catch((error) => {
        console.error(error)
        this.isCalculationExists = false
        this.isCalculationIdIncorrect = true
      })
    },
    resetData() {
      this.newCalculationId = ""
      this.isCalculationIdIncorrect = false
      this.isSubmitButtonActive = false
      this.isCalculationExists = true
    }
  }

}
</script>

<style scoped>
.method-edit__input {
  margin-top: 5px;
}

.method-edit__input-field {
  border: none;
  border-radius: 15px;
  background: #e8e8e8;
  color: #383838;
  margin-top: 3px;
  padding: 3px 20px;
  height: 24px;
  font-size: 12pt;
  text-align: left;
}


.method-edit__input-field_incorrect {
  color: #d05252;
  border-color: #d05252;
  background: #ffc9c9;
  border-width: 3px;
}

.method-edit__input-field:focus {
  outline: none;
}


.method-edit__submit-button {
  margin-left: 15px;
  border-radius: 15px;
  padding: 3px 10px;
  color: #dcdcdc;
  border: 2px solid #dcdcdc;
  text-align: center;
}

.method-edit__submit-button_active {
  border-color: #383838;
  color: #383838;
  text-decoration: none;
}

.method-edit__submit-button_active:hover {
  background-color: #d2cece;
}

.method__input-hint {
  color: #bbbbbb;
  font-family: 'Raleway', sans-serif;
  font-size: small;
  text-align: left;
  display: none;
}

.method__input-hint_active {
  display: initial;
}

.method__input-hint span {
  padding: 0;
  margin: 0;
}

.method-edit__not-existing-calculation {
  color: #d05252;
  font-size: 11pt;
  margin-left: 3px;
  display: none;
}

.method-edit__not-existing-calculation_active {
  display: initial;
}


</style>