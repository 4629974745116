<template>
  <Load></Load>
</template>

<script>
import Load from "@/components/Load";

export default {
  name: "Main",
  components: {
    Load
  }
}

</script>

<style scoped>

</style>