<template>
  <div class="load">
    <h1 class="load__title">Редактор результатов задачи</h1>
    <div class="load__input">
      <div class="load__input-line">
        <span class="load__input-mark-incorrect"
              v-bind:class="{ 'load__input-mark-incorrect_active': isTaskLinkIncorrect }">✖</span>
        <input v-model="taskLink"
               v-on:input="getLinks()"
               v-bind:class="{ 'load__input-field_incorrect': isTaskLinkIncorrect }"
               class="load__input-field" type="text" size="74"
               placeholder="cсылка на задачу">
      </div>
      <div class="load__input-hint" v-bind:class="{ 'load__input-hint_active': isTaskLinkIncorrect }">
        <p>Ссылка должна иметь следующий вид:</p>
        <ul>
          <li>https://plan.numdes.com/#/result/eac708a1-40a0-4e5d-ab2f-0d5343c8548e</li>
          <li>https://staging.plan.numdes.com/#/result/f5ad4ca6-bf4f-454d-9be8-08e84f9c7aa6</li>
        </ul>
      </div>
    </div>
    <router-link
        :is="isSubmitButtonActive ? 'router-link' : 'span' "
        class="load__submit-button"
        :to="{ name: 'Task' , params: { 'environment': environment,  'taskId': taskId}}"
        v-bind:class="{ 'load__submit-button_active': isSubmitButtonActive }">
      Продолжить
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Load',
  data: function () {
    return {
      taskLink: "",
      taskId: null,
      environment: null,
      isSubmitButtonActive: false,
      isTaskLinkIncorrect: false
    };
  },
  methods: {
    getLinks: function () {
      this.taskLink = this.taskLink.trim()
      if (this.taskLink === "") {
        this.environment = null
        this.taskId = null
        this.isSubmitButtonActive = false
        this.isTaskLinkIncorrect = false
        return
      }

      let re = new RegExp(
          "^https://(staging.)?plan.numdes.com/#/result/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$"
      )

      let isValid = re.test(this.taskLink)

      if (!isValid) {
        this.environment = null
        this.taskId = null
        this.isTaskLinkIncorrect = true
        this.isSubmitButtonActive = false
        return
      }

      // '00000000-0000-0000-0000-000000000000' -- 36 symbols
      this.taskId = this.taskLink.slice(-36)
      if (this.taskLink.includes("staging.")) {
        this.environment = "staging"
      } else {
        this.environment = "production"
      }

      let apolloClientName = this.environment + "ApolloClient"
      this.$root.$apolloProvider.defaultClient = this.$root.$apolloProvider.clients[apolloClientName]

      this.isSubmitButtonActive = true
      this.isTaskLinkIncorrect = false
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Raleway:wght@600&display=swap');

.load {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.load__title {
  font-family: 'Lato', sans-serif;
  margin-top: -20px;
}

.load__input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.load__input-field {
  border: none;
  border-radius: 15px;
  background: #e8e8e8;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  height: 30px;
  font-size: 12pt;
  text-align: left;
}

.load__input-field_incorrect {
  color: #d05252;
  border-color: #d05252;
  border-width: 3px;
  background: #ffc9c9;
}

.load__input-mark-incorrect {
  color: #d05252;
  font-size: 20pt;
  margin-left: -25px;
  margin-top: 18px;
  position: absolute;
  visibility: hidden;
}

.load__input-mark-incorrect_active {
  visibility: visible;
}

.load__input-field:focus {
  outline: none;
}

.load__input-hint {
  color: #bbbbbb;
  font-family: 'Raleway', sans-serif;
  font-size: small;
  text-align: left;
  display: none;
}

.load__input-hint_active {
  display: initial;
}

.load__input-hint p, .load__input-hint ul {
  padding: 0;
  margin: 0;
}

.load__input-hint ul {
  padding-left: 20px;
}

.load__submit-button {
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  color: #dcdcdc;
  border: 2px solid #dcdcdc;
}

.load__submit-button_active {
  border-color: #252525;
  color: #252525;
  text-decoration: none;
}

.load__submit-button_active:hover {
  background-color: #e8e8e8;
}

</style>
