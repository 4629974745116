<template>
  <div class="method">
    <div class="method__header">
      <div class="method__status"
           title="Статус расчёта метода"
           v-bind:style="{ backgroundColor:  statusBackgroundColor}"
           v-bind:class="{ 'not-visible': !isExists }"></div>
      <img class="icon method__create" src="../assets/create.svg"
           v-on:click="createTaskCalculation()"
           title="Создать метод"
           alt="Создать метод" v-bind:class="{ 'not-visible': isExists }">
      <span class="method__name">{{ name }}</span>
      <img class="icon method__final" src="../assets/final.svg"
           title="Убрать 'является окончательным результатом этапа'"
           v-on:click="handleFinal()"
           alt="Убрать 'является окончательным результатом этапа'"
           v-bind:class="{ 'not-visible': !isExists || !isFinalResult }">
      <img class="icon method__not-final" src="../assets/not-final.svg"
           v-on:click="handleFinal()"
           title="Выбрать окончательным результатом этапа"
           alt="Выбрать окончательным результатом этапа" v-bind:class="{ 'not-visible': !isExists || isFinalResult }">
    </div>
    <div class="method__operations" v-bind:class="{ 'not-visible': !isExists }">
      <img class="icon method__show-edit"
           v-on:click="handleEditClick()"
           v-bind:class="{ 'clicked': isExists && showEdit }"
           src="../assets/edit.svg" title="Изменить calculation_id"
           alt="Изменить calculation_id">
      <img class="icon method__show-history"
           v-on:click="handleHistoryClick()"
           v-bind:class="{ 'clicked': isExists && showHistory }"
           src="../assets/history.svg" title="Показать историю изменений метода"
           alt="Показать историю изменений метода">
      <img class="icon method__copy"
           v-on:click="handleCopyClick()"
           src="../assets/copy.svg" title="Скопировать результаты метода"
           alt="Скопировать результаты метода">
      <img class="icon method__delete"
           v-on:click="handleDeleteClick()"
           src="../assets/delete.svg" title="Удалить метод"
           alt="Удалить метод">
    </div>
    <div class="method__method">
      <div class="method__method-edit"
           v-bind:class="{ 'visible': isExists && showEdit }">
        <MethodEdit
            ref="methodEdit"
            v-bind:current-calculation-id="calculationId"
            v-bind:description="description"
            v-bind:task-calculation-id="taskCalculationId"
        ></MethodEdit>
      </div>
      <div class="method__method-history"
           v-bind:class="{ 'visible': isExists && showHistory }">
        <MethodHistory
            v-bind:current-calculation-id="calculationId"
            v-bind:history="history"
        ></MethodHistory>
      </div>
    </div>
  </div>
</template>

<script>


import MethodHistory from "@/components/MethodHistory";
import MethodEdit from "@/components/MethodEdit";
import gql from "graphql-tag";

export default {
  name: "Method",
  components: {MethodEdit, MethodHistory},
  computed: {
    statusBackgroundColor: function () {
      if (this.status === "NEW") {
        return "#bdbdbd"
      } else if (this.status === "RUNNING") {
        return "#6ca0f3"
      } else if (this.status === "FAILED") {
        return "#f65454"
      } else if (this.status === "FINISHED") {
        return "#85d28c"
      }
      return "#000"
    },
    isExists: function () {
      return !(this.status === undefined || this.status === null);
    }
  },
  data() {
    return {
      taskCalculationId: null,
      calculationId: null,
      description: null,
      isFinalResult: false,
      status: null,
      history: [],
      showHistory: false,
      showEdit: false
    };
  },
  props: {
    name: {
      type: String
    },
    methodId: {
      type: String
    },
    stageId: {
      type: String
    },
    taskId: {
      type: String
    }
  },
  methods: {
    handleCopyClick() {
      this.$confirm(
          {
            message: `Результат метода "${this.name}" будет скопирован. Вы точно уверены?`,
            button: {
              yes: 'Копировать',
              no: 'Отмена',
            },
            callback: confirm => {
              if (confirm) {
                this.copyTaskCalculation()
              }
            }
          }
      )
    },
    handleDeleteClick() {
      this.$confirm(
          {
            message: `Результат метода "${this.name}" будет удален безвозвратно. Это может нарушить результаты задачи. Вы точно уверены?`,
            button: {
              yes: 'Удалить',
              no: 'Отмена',
            },
            callback: confirm => {
              if (confirm) {
                this.deleteTaskCalculation()
              }
            }
          }
      )
    },
    handleEditClick() {
      this.showEdit = !this.showEdit
    },
    handleHistoryClick() {
      this.showHistory = !this.showHistory
    },
    createTaskCalculation() {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($input: CreateTaskCalculationInput!) {
                taskCalculationMutation {
                    createTaskCalculation(input: $input) {
                        record {
                            taskCalculationId
                            calculationId
                            status
                            isFinalResult
                            description
                            history {
                                created
                                oldCalculation {
                                    calculationId
                                    description
                                }
                                newCalculation {
                                    calculationId
                                    description
                                }
                            }
                        }
                    }
                }
            }`,
            variables: {
              input: {
                methodId: this.methodId,
                stageId: this.stageId,
                taskId: this.taskId
              }
            }
          }).then((data) => {
        data = data.data.taskCalculationMutation.createTaskCalculation.record
        this.taskCalculationId = data.taskCalculationId
        this.calculationId = data.calculationId
        this.status = data.status
        this.isFinalResult = data.isFinalResult
        this.history = data.history
        this.showHistory = false
        this.showEdit = false
      }).catch((error) => {
        console.error(error)
      })
    },
    deleteTaskCalculation() {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($taskCalculationId: Uuid!) {
                taskCalculationMutation {
                    forceDeleteTaskCalculation(taskCalculationId: $taskCalculationId) {
                        ok
                    }
                }
            }`,
            variables: {
              'taskCalculationId': this.taskCalculationId
            }
          }).then(() => {
        this.taskCalculationId = null
        this.calculationId = null
        this.status = null
        this.showHistory = false
        this.showEdit = false
        this.$refs.methodEdit.resetData()
      }).catch((error) => {
        console.error(error)
      })
    },
    handleFinal() {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($input: SetIsFinalResultInput!) {
                taskCalculationMutation {
                    setIsFinalResult(input: $input) {
                        ok
                    }
                }
            }`,
            variables: {
              input: {
                taskCalculationId: this.taskCalculationId,
                isFinalResult: !this.isFinalResult
              }
            }
          }).then(() => {
        this.isFinalResult = !this.isFinalResult
      }).catch((error) => {
        console.error(error)
      })
    },
    copyTaskCalculation() {
      this.$apollo.mutate(
          {
            mutation: gql`mutation ($taskCalculationId: Uuid!) {
                taskCalculationMutation {
                    copyTaskCalculationsResult(taskCalculationId: $taskCalculationId) {
                        record {
                            taskCalculationId
                            calculationId
                            status
                            isFinalResult
                            description
                            history {
                                created
                                oldCalculation {
                                    calculationId
                                    description
                                }
                                newCalculation {
                                    calculationId
                                    description
                                }
                            }
                        }
                    }
                }
            }`,
            variables: {
              taskCalculationId: this.taskCalculationId
            }
          }).then((data) => {
        data = data.data.taskCalculationMutation.copyTaskCalculationsResult.record
        this.taskCalculationId = data.taskCalculationId
        this.calculationId = data.calculationId
        this.status = data.status
        this.isFinalResult = data.isFinalResult
        this.history = data.history
      }).catch((error) => {
        console.error(error)
      })
    },
    handleReload() {
      this.$apollo.queries.info.refetch()
    }
  },
  apollo: {
    info: {
      query: gql`query info($taskId: Uuid!, $stageId: Uuid!, $methodId: Uuid!)
        {
            taskCalculationByTaskId(taskId: $taskId, stageId: $stageId, methodId: $methodId) {
                taskCalculationId
                calculationId
                status
                isFinalResult
                description
                history {
                    created
                    oldCalculation {
                        calculationId
                        description
                    }
                    newCalculation {
                        calculationId
                        description
                    }
                }
            }
        }`,
      variables() {
        return {
          taskId: this.taskId,
          stageId: this.stageId,
          methodId: this.methodId
        };
      },
      update(data) {
        let entry = data.taskCalculationByTaskId
        if (entry === null) {
          this.taskCalculationId = null
          this.calculationId = null
          this.status = null
          this.isFinalResult = null
          this.history = []
          return
        }
        this.taskCalculationId = entry.taskCalculationId
        this.calculationId = entry.calculationId
        this.status = entry.status
        this.isFinalResult = entry.isFinalResult
        this.history = entry.history
        return data
      },
      error(error) {
        console.log('errors', error.graphQLErrors)
      }
    }
  }
}
</script>

<style scoped>
.method {
  display: flex;
  flex-direction: column;
}

.method__header {
  display: flex;
}

.method__name {
  margin-top: -4px;
  text-align: left;
  font-size: 14pt;
  margin-left: 28px;
  margin-right: 12px;
}

.method__operations {
  margin-top: 10px;
  display: initial;
}

.method__status {
  position: absolute;
  width: 8px;
  height: 42px;
}

.method__create {
  position: absolute;
}

.icon {
  height: 15px;
  width: 15px;
}

.method__final, .method__not-final {
  height: 18px;
  width: 18px;
}

.method__operations {
  margin-top: 10px;
}

.method__show-edit, .method__show-history, .method__delete, .method__copy {
  margin-left: 30px;
  padding: 4px;
}

.clicked {
  border-radius: 7px;
  padding: 4px;
  background-color: #dadada;
}

.method__delete {
  float: right;
  margin-right: 10px;
}

.method__method {
  margin-left: 32px;
}

.method__method-edit {
  display: none;
  margin-top: 5px;
}

.method__method-history {
  display: none;
  margin-top: 5px;
}

.method__final {
  display: initial;
}

.method__not-final {
  display: initial;
}

hr {
  width: 100%;
  display: none;
}

.visible {
  display: initial;
}

.not-visible {
  display: none;
}


</style>